<template>
    <section id="disclaimer">
    <div class="container">
        <h3>General Disclaimer</h3>

        <p>The information contained in this website is for general information purposes only. <br>
            <br> 
            The information is provided by MSM Aviation and while we endeavour to keep the 
            information up to date and correct, we make no representations or warranties 
            of any kind, express or implied, about the completeness, accuracy, reliability, 
            suitability or availability with respect to the website or the information, 
            products, services, or related graphics contained on the website for any purpose. <br>
            Any reliance you place on such information is therefore strictly at your own risk. <br>
            <br>
            In no event will we be liable for any loss or damage including without limitation, 
            indirect or consequential loss or damage, or any loss or damage whatsoever arising 
            from loss of data or profits arising out of, or in connection with,
            the use of this website. Through this website you are able to link to other websites 
            which are not under the control of MSM Aviation. We have no control over the nature, 
            content and availability of those sites. <br>
            <br>
            The inclusion of any links does not necessarily imply a recommendation or endorse the 
            views expressed within them. <br>
            <br>
            Every effort is made to keep the website up and running smoothly. <br>
            However, MSM Aviation takes no responsibility for, and will not be liable for, 
            the website being temporarily unavailable due to technical issues beyond our control. 
        </p>
    </div>
    </section>
</template>

<script>
export default {
    name: 'Disclaimer'
}

</script>