<template>

<!--SECTION REQUEST-->
<section id="request">
    <div class="fullcontainer">
        <!-- <div class="request-logo"></div> -->
        <RequestAQuoteComponent />
    </div>
</section>
</template>

<script>
import RequestAQuoteComponent from '@/components/RequestAQuoteComponent.vue';

export default {
    name:'RequestPage',
    components:{
        RequestAQuoteComponent
    },
}

</script>

<style scoped>

</style>