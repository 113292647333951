<template>
  <NavComponent />
  <router-view />
  <FooterComponent />
  <!-- <VuePreloader v-if="showComponent"
    background-color="#050B13"
    color="#ffffff"
    transition-type="fade-up"
    :loading-speed="20"
    :transition-speed="1000"
    @loading-is-over="loadingIsOver=true"
    @transition-is-over="transitionIsOver"
  ></VuePreloader> -->


</template>

<script>
import NavComponent from '@/components/layout/NavComponent.vue';
import FooterComponent from '@/components/layout/FooterComponent.vue';
import '/node_modules/vue-preloader/dist/style.css';
import { VuePreloader } from 'vue-preloader';
import Cookies from 'js-cookie';

export default {
  name: 'App',
  data() {
    return{
      loadingIsOver:false
    }
  },
  components:{
      NavComponent,
      FooterComponent,
      VuePreloader
  },
  computed: {
      showComponent() {
        return this.$store.state.showComponent;
      }
  },
  async mounted() {
    await new Promise(resolve => setTimeout(resolve, 3000));
    this.$store.commit('COMPONENT_SET_FALSE');
    
    // Add information on Captcha cookie
    Cookies.set('_GRECAPTCHA', 'cookie_value', { partitioned: true, secure:true });
  },
}
</script>

