import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '../public/styles.css';
import { VueReCaptcha } from 'vue-recaptcha-v3'
import { createAuth0 } from '@auth0/auth0-vue';

const siteKey= process.env.VUE_APP_RECAPTCHA_SITE_KEY

const app = createApp(App).use(store).use(router).use(VueReCaptcha, { siteKey })

// app.use(
//     createAuth0({
//       domain: process.env.VUE_APP_AUTH0_DOMAIN,
//       clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
//       authorizationParams: {
//         redirect_uri: window.location.origin
//       }
//     })
//   );



app.mount('#app')
