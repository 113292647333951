<template>

<!--CAPABILITIES-->
<section id="ourCapabilities">
        <div class="page1">
            <div class="container2">
                <div class="service s1">
                    <div class="container3-1">
                        <div class="number">
                            <p class="chiffre serv1">01</p>
                        </div>
                        <div class="text t1">
                            <h3 class="serv1">SUPPLY HELICOPTER PARTS</h3>
                            <p class="subtext">We can supply structural parts or engine components from the most popular OEM</p>   
                        </div>   
                    </div>
                    <div class="container3-2">
                        <div class="grid-container">
                            <div class="grid-item">
                                <img src="img/brand1.png" alt="Airbus logo">
                            </div>
                            <div class="grid-item">
                                <img src="img/brand2.svg" alt="Safran logo">
                            </div>
                            <div class="grid-item">
                                <img src="img/brand3.svg" alt="Leonardo logo">
                            </div>
                            <div class="grid-item">
                                <img src="img/brand4.png" alt="Pratt and Whitney logo">
                            </div>
                            <div class="grid-item">
                                <img src="img/brand5.png" alt="HoneyWell logo">
                            </div>
                            <div class="grid-item">
                                <img src="img/brand6.png" alt="Sikorsky logo">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="service s2">
                    <div class="container3-1">
                        <div class="number">
                            <p class="chiffre serv2">02</p>
                        </div>
                        <div class="text t2">
                            <h3 class="serv2">AIRBUS REPAIRS</h3>
                            <p class="subtext">Our capabilities cover a wide range of repair services 
                                for Airbus helicopters 
                            </p>
                        </div>
                    </div>   
                </div>
                <div class="service s3">
                    <div class="container3-1">
                        <div class="number">
                            <p class="chiffre serv3">03</p>
                        </div>
                        <div class="text t3">
                            <h3 class="serv3">SUPPLY FLOATATION DEVICE</h3>
                            <p class="subtext">We offer reliable and compliant flotation solutions for various helicopter models</p>
                        </div>
                    </div>  
                </div>

                <div class="service s4">
                    <div class="container3-1">
                        <div class="number">
                            <p class="chiffre serv4">04</p>
                        </div>
                        <div class="text t4">
                            <h3 class="serv4">SUPPLY OF CONSUMABLES</h3>
                            <p class="subtext">From filters and lubricants to adhesives and seals, we provide consumables for aircraft maintenance and operations
                            </p>
                        </div>
                    </div>   
                </div>

                <div class="service s5">
                    <div class="container3-1">
                        <div class="number">
                            <p class="chiffre serv5">05</p>
                        </div>
                        <div class="text t5">
                            <h3 class="serv5">AVIONICS SERVICES</h3>
                            <p class="subtext">We provide avionics solutions straight from OEM to meet
                                your aircraft's navigation, communication, and flight management needs 
                            </p>
                        </div>
                    </div>
                    <div class="container3-2">
                        <div class="grid-container grid-container2">
                            <div class="grid-item">
                                <img src="img/brand7.png" alt="Thales logo">
                            </div>
                            <div class="grid-item">
                                <img src="img/brand5.png" alt="Honeywell logo">
                            </div>
                            <div class="grid-item">
                                <img src="img/brand8.png" alt="Garmin logo">
                            </div>
                            <div class="grid-item">
                            </div>
                        </div>
                    </div>    
                </div>
            </div>
        </div>
        <div id="page2">
            <div class="container4">
                <div class="container4-bis">
                    <div class="container5">
                        <div class="container6-1 reveal">
                            <div class="number">
                                <p class="chiffre serv1">01</p>
                            </div>
                            <div class="text t1">
                                <h3 class="serv1 serv">SUPPLY HELICOPTER PARTS</h3>
                                <p class="subtext-2">MSM Aviation offers a wide range of capabilities in providing 
                                    both structural components and engine components for helicopters.</p>
                            </div>
                        </div>
                        
                        <div class="container6-2 reveal">
                            <div class="container7-1">
                                <h4>STRUCTURAL COMPONENTS</h4>
                                <p class="subtext-2">We offer a range structural components parts directly from OEM
                                    on various and most popular aircrafts. Our product range goes 
                                    from dynamics to associated hardware. 
                                </p>
                            </div>
                            <!-- <div id="dot"></div> -->
                            <div class="container7-1">
                                <div class="container8-1 focus">
                                    <div class="tile">
                                        <img src="img/brand1.png" alt="Airbus logo">
                                    </div>
                                    <div class="specifications">
                                        <div class="grid-container3">
                                            <div class="grid-item">
                                                <p>AS350/AS355 Ecureuil / Astar</p>
                                            </div>
                                            <div class="grid-item">
                                                <p>AS332/EC225 SuperPuma/Cougar/Caracal</p>
                                            </div>
                                            <div class="grid-item">
                                                <p>AS365 Dauphin</p>
                                            </div>
                                            <div class="grid-item">
                                                <p>EC120 Colibri</p>
                                            </div>
                                            <div class="grid-item">
                                                <p></p>
                                            </div>
                                            <div class="grid-item">
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="container8-1 focus">
                                    <div class="tile">
                                        <img src="img/brand3.svg" alt="Leonardo logo">
                                    </div>
                                    <div class="specifications">
                                        <div class="grid-container3">
                                            <div class="grid-item">
                                                <p>AW109</p>
                                            </div>
                                            <div class="grid-item">
                                                <p>AW169</p>
                                            </div>
                                            <div class="grid-item">
                                                <p>AW139</p>
                                            </div>
                                            <div class="grid-item">
                                                <p>AW189</p>
                                            </div>
                                            <div class="grid-item">
                                                <p></p>
                                            </div>
                                            <div class="grid-item">
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="container8-1 focus">
                                    <div class="tile sikorsky-tile focus">
                                        <img src="img/brand6.png" alt="Sikorsky logo">
                                    </div>
                                    <div class="specifications focus">
                                        <div class="grid-container3">
                                            <div class="grid-item">
                                                <p>S-76</p>
                                            </div>
                                            <div class="grid-item">
                                                <p></p>
                                            </div>
                                            <div class="grid-item">
                                                <p></p>
                                            </div>
                                            <div class="grid-item">
                                                <p></p>
                                            </div>
                                            <div class="grid-item">
                                                <p></p>
                                            </div>
                                            <div class="grid-item">
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="container7-1">
                                <h4>ENGINE COMPONENTS</h4>
                                <p class="subtext-2">As well, we provide engine components for aircraft from leading manufacturers
                                    in the sector. From gas turbine engines from SAFRAN to Lycoming turboshaft engines,
                                    we provide a comprehensive range of engine parts. 
                                </p>
                            </div>
                            <div class="container7-1">
                                <div class="container8-1 focus">
                                    <div class="tile">
                                        <img src="img/brand2.svg" alt="Safran logo">
                                    </div>
                                    <div class="specifications">
                                        <div class="grid-container3">
                                            <div class="grid-item">
                                                <p>TurboMeca parts Level 1&2</p>
                                            </div>
                                            <div class="grid-item">
                                                <p></p>
                                            </div>
                                            <div class="grid-item">
                                                <p>Series of turboshaft:</p>
                                            </div>
                                            <div class="grid-item">
                                                <p>ARRIEL</p>
                                            </div>
                                            <div class="grid-item">
                                                <p>MAKILA</p>
                                            </div>
                                            <div class="grid-item">
                                                <p>ARRIUS</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="container8-1 focus">
                                    <div class="tile">
                                        <img src="img/brand4.png" alt="Pratt and Whitney logo">
                                    </div>
                                    <div class="specifications">
                                        <div class="grid-container3">
                                            <div class="grid-item">
                                                <p>Various series engines:</p>
                                            </div>
                                            <div class="grid-item">
                                                <p>PW150, PW200, PW300, </p>
                                            </div>
                                            <div class="grid-item">
                                                <p>PT6, JT15D, PW100, </p>
                                            </div>
                                            <div class="grid-item">
                                                <p>PW500, PW600 and PW900</p>
                                            </div>
                                            <div class="grid-item">
                                                <p></p>
                                            </div>
                                            <div class="grid-item">
                                                <p> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="container8-1 focus">
                                    <div class="tile sikorsky-tile">
                                        <img src="img/brand5.png" alt="Honeywell logo">
                                    </div>
                                    <div class="specifications">
                                        <div class="grid-container3">
                                            <div class="grid-item">
                                                <p>Lycoming turboshaft engines / LTS101</p>
                                            </div>
                                            <div class="grid-item">
                                                <p></p>
                                            </div>
                                            <div class="grid-item">
                                                <p>Any Honeywell parts</p>
                                            </div>
                                            <div class="grid-item">
                                                <p></p>
                                            </div>
                                            <div class="grid-item">
                                                <p></p>
                                            </div>
                                            <div class="grid-item">
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container5 reveal">
                        <div class="container6-1 ">
                            <div class="number">
                                <p class="chiffre serv2">02</p>
                            </div>
                            <div class="text t2">
                                <h3 class="serv2 serv">AIRBUS REPAIRS</h3>
                            </div>
                        </div>
                        <div class="container6-2 single-text">
                            <p>Choose MSM Aviation as your trusted partner for Airbus helicopter repairs. 
                                With our partnerships with several PART 145 repair stations and access to OEM
                                parts we guarantee repairs that meet Airbus's standards.</p>
                            <p>From general maintenance and component replacements to Makila engine or epicyclics repairs, 
                                we have the knowledge and skills to handle any 
                                repair task effectively. 
                            </p>
                        </div>
                    </div>
                    <div class="container5 reveal">
                        <div class="container6-1">
                            <div class="number">
                                <p class="chiffre serv3">03</p>
                            </div>
                            <div class="text t3">
                                <h3 class="serv3 serv">SUPPLY FLOTATION DEVICE</h3>
                            </div>
                        </div>
                        <div class="container6-2 single-text">
                            <p>Emergency Flotation Systems (EFS) are emergency systems installed on larger 
                                commercial and military helicopters in order to prevent helicopters from sinking 
                                or capsizing in the event of a crash landing or controlled ditching onto a body of 
                                water. 
                            </p>
                            <p>Our capabilities cover reliable and compliant flotation solutions for various 
                                helicopter models. Here are some items we offer:  
                            </p>
                            <ul>
                                <li>Life rafts</li>
                                <li>Flotation device</li>
                                <li>Cylinder bottles</li>
                                <li>Life jackets</li>
                                <li>Booster pumps</li>
                                <li>and more</li>
                            </ul>
                        </div>
                    </div>
                    <div class="container5 reveal">
                        <div class="container6-1">
                            <div class="number">
                                <p class="chiffre serv4">04</p>
                            </div>
                            <div class="text t4">
                                <h3 class="serv4 serv">SUPPLY OF CONSUMABLES</h3>
                            </div>
                        </div>
                        <div class="container6-2 single-text">
                            <p>Our offer also consist of a variety of consumables and hardware required 
                                for helicopter maintenance and repairs, including: 
                            </p>
                            <ul>
                                <li>Ground equipement</li>
                                <li>Tools and harware</li>
                                <li>Painting</li>
                                <li>Cabin interior</li>
                                <li>Oil, jet oil, lubricants</li>
                                <li>and more</li>
                            </ul>
                        </div>
                    </div>
                    <div class="container5 reveal">
                        <div class="container6-1">
                            <div class="number">
                                <p class="chiffre serv5">05</p>
                            </div>
                            <div class="text t5">
                                <h3 class="serv5 serv">AVIONICS SERVICES</h3>
                            </div>
                        </div>
                        <div class="container6-2 avionics-container">
                            <div class="container7-1">
                                <p class="subtext-2">We closely work with OEM to meet your aircraft's navigation, communication, 
                                    and flight management needs for helicopters and airplanes. 
                                    We offer components as well as repair services.  
                                </p>
                            </div>
                            <div class="container7-1">
                                <div class="container8-1 focus">
                                    <div class="tile">
                                        <img src="img/brand7.png" alt="Thales logo">
                                    </div>
                                    <div class="specifications less-padding">
                                        <div class="grid-container3">
                                            <div class="grid-item">
                                                <p class="bold">Thales Electronical Systems:</p>
                                            </div>
                                            <div class="grid-item">
                                                <p class="bold">Thales Avionics:</p>
                                            </div>
                                            <div class="grid-item">
                                                <p>Starter Generator,</p>
                                            </div>
                                            <div class="grid-item">
                                                <p>Automatic Flight Control System,  </p>
                                            </div>
                                            <div class="grid-item">
                                                <p>Electrical Power Generation System</p>
                                            </div>
                                            <div class="grid-item">
                                                <p>Interactive Avionics Display IAD 68…</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="container8-1 focus ">
                                    <div class="tile">
                                        <img src="img/brand5.png" alt="HoneyWell logo">
                                    </div>
                                    <div class="specifications less-padding">
                                        <div class="grid-container3">
                                            <div class="grid-item">
                                                <p>Autopilots & indicators</p>
                                            </div>
                                            <div class="grid-item">
                                                <p>Flight Management Systems</p>
                                            </div>
                                            <div class="grid-item">
                                                <p>Cockpit Displays</p>
                                            </div>
                                            <div class="grid-item">
                                                <p>Image Processing Module</p>
                                            </div>
                                            <div class="grid-item">
                                                <p>Control Display Units</p>
                                            </div>
                                            <div class="grid-item">
                                                <p>Integrated Flight Decks</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="container8-1 focus">
                                    <div class="tile sikorsky-tile">
                                        <img src="img/brand8.png" alt="Garmin logo">
                                    </div>
                                    <div class="specifications less-padding">
                                        <div class="grid-container3">
                                            <div class="grid-item">
                                                <p>Flight decks & display</p>
                                            </div>
                                            <div class="grid-item">
                                                <p>Audio Panels</p>
                                            </div>
                                            <div class="grid-item">
                                                <p>Navigation & radios</p>
                                            </div>
                                            <div class="grid-item">
                                                <p>Altimeters</p>
                                            </div>
                                            <div class="grid-item">
                                                <p>ADS-B & transponders</p>
                                            </div>
                                            <div class="grid-item">
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container4-bis button">
                    <div class="discover appear"><router-link to="/request">REQUEST A QUOTE</router-link></div>
                </div>
            </div>
        </div>
    
</section>


</template>

<script>
export default {
    name: 'Capabilities',
    mounted () {
        tiles();
    }
}

</script>