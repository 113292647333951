<template>
<div aria-label="Page navigation" class="nav-pagination">
    <ul class="pagination">
        <!-- Previous Page Link -->
        <li class="page-item" :class="{ disabled: props.currentPage === 1 }">
            <a class="page-link" href="#" aria-label="Previous" @click.prevent="goToPage(props.currentPage - 1)">
                <span aria-hidden="true">&laquo;</span>
            </a>
        </li>

        <!-- Page Number Links -->
        <li
            v-for="page in paginatedPages"
            :key="page"
            class="page-item"
            :class="{ active: page === props.currentPage }"
        >
            <a class="page-link" href="#" @click.prevent="goToPage(page)">{{ page }}</a>
        </li>

        <!-- Next Page Link -->
        <li class="page-item" :class="{ disabled: props.currentPage === props.totalPages }">
            <a class="page-link" href="#" aria-label="Next" @click.prevent="goToPage(props.currentPage + 1)">
                <span aria-hidden="true">&raquo;</span>
            </a>
        </li>
    </ul>
</div>
</template>


<script setup>
import { computed } from 'vue'


const props = defineProps(['totalPages', 'currentPage'])
const emits = defineEmits(['setCurrentPage'])

// Computed property to calculate the range of pages to display
const paginatedPages = computed(() => {
    const maxPages = 5; // Maximum number of pages to display
    const half = Math.floor(maxPages / 2);

    let start = props.currentPage - half;
    let end = props.currentPage + half;

    // Adjust start and end if we're near the start or end of the total pages
    if (start < 1) {
        start = 1;
        end = Math.min(maxPages, props.totalPages);
    }
    if (end > props.totalPages) {
        end = props.totalPages;
        start = Math.max(1, props.totalPages - maxPages + 1);
    }

    // Generate the array of page numbers to display
    const pages = [];
    for (let i = start; i <= end; i++) {
        pages.push(i);
    }
    return pages;
});

const goToPage = (page) => {
    if (page >= 1 && page <= props.totalPages && page !== props.currentPage) {
        emits('setCurrentPage', page);
    }
}


</script>