<template>

<!--SECTION WHO WE ARE-->
<section id="whoWeAre">
    
    <div id="fullpage1">
        <div id="bannerimg" class="mobile-hide desktop-hide tablet-show"></div>
        <div class="container1">
            <div class="container2">
                <div class="container3-1 anim1">
                    <p class="first-line p1">
                        MSM Aviation is a <strong>French company</strong> specializing in providing high-quality, 
                        and certified parts for a wide range of helicopter aircrafts.
                    </p>
                    <p class ="sous">
                        Our primary objective is to be a <strong>reliable</strong> and <strong>transparent worldwide supplier</strong> 
                        of parts for most popular helicopter aircrafts. 
                    </p>
                </div>
                <div class="container3-1 anim2">
                    <p class="first-line p2">
                        <strong>With 13 years of industry experience</strong>, we have built a strong reputation
                        for our customer service and expertise. 
                    </p>
                    <p class ="sous">
                        Our CEO, Sam Monnier, <strong>pilot, flight instructor</strong> and former <strong>sales manager</strong>,  
                        has created the company following its success in <strong>deploying a distribution network</strong> close to manufacturers 
                        and a brokerage service to international markets. His <strong>technical knowledge</strong> of parts 
                        is widely appreciated by its customers
                    </p>
                </div>
                <div class="container3-1 anim3">
                    <p class="first-line p3">To deliver the <strong>highest standard of products</strong> to our customers,
                        we have forged <strong>strong partnerships</strong> with reputable suppliers in the helicopter industry. 
                    </p>
                    <p class ="sous">
                        We have a partnership with several <strong>service centers / PART 145 </strong>repair stations. 
                        These partnerships form the foundation of our success, enabling us to consistently provide genuine, certified parts that meet the most stringent aviation standards.   
                    </p>
                </div>
            </div>
        </div>
        <div id="sideimg"></div>
    </div>
    
    <div id="fullpage2">
        <div class="container4">
            <div class="container5">
                <h2>WHY CHOOSE US ?</h2>
                <div class="choose-points">
                    <div class="container5-1 appear">
                        <img class= "i1 icone" src="img/icone1.svg" alt="icone broad product range">                      
                        <!-- <i class="fa-solid fa-warehouse opacity1 i1"></i> -->
                        <div class="text-services">   
                            <h3 class="h3-1">BROAD PRODUCT RANGE AND SERVICES</h3>
                            <p class="sous-text-service">
                                At MSM Aviation, we specialize in providing <strong>top-notch helicopter parts</strong> to meet your specific needs. 
                                Our <strong>extensive inventory</strong> consists of a wide range of components, from <strong>engine parts</strong> to <strong>avionics systems</strong>, 
                                ensuring that we can support various helicopter models and manufacturers.<br>
                                <br>
                                We also offer services such as <strong>maintenance repairs</strong>, firefighting operations, 
                                upgrades in helicopter models and consulting for custom-built solutions.
                            </p>
                        </div>
                    </div>
                    <div class="container5-1 appear">
                        <img class= "i2 icone" src="img/icone2.svg" alt="icone technical expertise">
                        <!-- <i class="fa-solid fa-screwdriver-wrench opacity2 i2"></i>  -->
                        <div class="text-services">   
                            <h3 class="h3-2">TECHNICAL EXPERTISE</h3>
                            <p class="sous-text-service">
                                In our team we have <strong>pilots</strong> and professionals who possess <strong>in-depth knowledge</strong> of helicopter parts 
                                and maintenance. <br><br>
                                Whether you require <strong>technical assistance, expert advice, or consultation</strong>, our knowledgeable
                                staff is always ready to assist you with your specific requirements.
                            </p>
                        </div>                 
                    </div>
                    <div class="container5-1 appear">
                        <img class= "i3 icone" src="img/icone3.svg" alt="icone reliable service">
                        <!-- <i class="fa-solid fa-handshake opacity2 i3"></i>  -->
                        <div class="text-services">   
                            <h3 class="">RELIABLE SERVICE</h3>
                            <p class="sous-text-service">
                                We understand the importance of <strong>minimizing downtime for helicopter owners</strong> and operators. 
                                When you choose us, you can count on prompt order processing,
                                speedy delivery with <strong>associated certifications</strong> and <strong>after-sales service and support</strong>. 
                            </p>
                        </div>                 
                    </div>
                    <div class="container5-1 appear">
                        <img class= "i1 icone" src="img/icone4.svg" alt="icone direct line">
                        <!-- <i class="fa-solid fa-headset opacity2 i1"></i>  -->
                        <div class="text-services">   
                            <h3 class="">CLIENT DIRECT LINE</h3>
                            <p class="sous-text-service">
                                Because helicopter field is a <strong>sensitive and complex ecosystem</strong>, 
                                we always have a <strong>direct line with our customers</strong> to keep them constantly up to date 
                                with the status of their orders. <br><br>
                                We ensure that every customer receives <strong>personalized attention</strong> and assistance 
                                from the initial quotation to the delivery of parts or service.                         
                            </p>
                        </div>                 
                    </div>
                    <div class="container5-1 appear">
                        <img class= "i2 icone" src="img/icone5.svg" alt="icone certified company">
                        <!-- <i class="fa-solid fa-shield-halved opacity2 i2"></i>  -->
                        <div class="text-services">   
                            <h3 class="">CERTIFIED COMPANY</h3>
                            <p class="sous-text-service">
                                Our company is certified ISO 9001 and is quarterly audited.                       
                            </p>
                            <p class="download"><a href="/assets/Label ISO 9001 - MSM Aviation.pdf">DOWNLOAD ISO</a></p>
                        </div>                 
                    </div>
                </div>
            </div>
            <div class="container5-2">
                <router-link :to="'/capabilities'" class="discover appear"> <div>DISCOVER OUR CAPABILITIES</div></router-link>
            </div>
            <div class="vide"></div>
        </div>
    </div>
</section>

</template>

<script>
export default {
    name:'WhoWeAre'
}
</script>