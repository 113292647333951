<template>
    <section id="dashboard">
        <h4>Welcome to the dashboard !</h4>
    </section>
</template>

<script>
export default{
    name: 'DashboardHome'
}

</script>

<style scopped>
#dashboard{
    height: calc(100vh - var(--heightFooter));
    padding-top: var(--heightNav);
    background-color: whitesmoke;  
}

#dashboard h4{
    color: black;
    text-align: center;
    font-size: 2rem;
    font-weight: 500
}


</style>