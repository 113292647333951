<template>
    <div id="error404">
        <p class="title-oops">Oops</p>
        <p class="subtitle-oops">404 - Page Not Found</p>
        <p class="message">
            It looks like you've stumbled upon a page that doesn't exist.
            We're sorry for the inconvenience. Let's get you back on track!
        </p>
        <div class="button">
            <div class="discover appear"><router-link to="/">BACK HOME</router-link></div>
        </div>
    </div>
</template>

<script>

export default {
    name: '404',
    
}

</script>