<template>
    <div id=requestComponent class="form">
        <form @submit.prevent="onSubmit">
            <h3>Request for quotation</h3>
            <p class="subtext">Whether you're involved in aviation maintenance, helicopter repairs, 
                or any other related field, we are here to assist you.
            </p>
            <h4>CONTACT INFORMATION</h4>
                <label for="">Company name *</label>
                <input type="text" id="company" name="company" placeholder="Your company" required v-model="company">
                
                <label for="">Email *</label>
                <input type="email" id="email" name="email" placeholder="example@email.com" required v-model="email">
            
                <label for="">Phone number</label>
                <input type="tel" id="phone" name="phone" placeholder="+3362365842527" v-model="phone">
            <h4>HELICOPTER PARTS DETAILS</h4>
                <label for="">Aircraft type *</label>
                <input type="text" id="aircraft" name="aircraft" placeholder="EC220 - AS365" v-model="aircraft">
                
                <label for="message">Specific parts or components needed & quantity required *</label>
                <textarea id="message" name="message" placeholder=
                "350A32-5010-00  ROTOR BRAKE ASSY qty 1 " required v-model="message"></textarea>
            
            <p class="privacy-terms">Your privacy is important to us. We strictly restrict the use of your data to process your inquiries, deliver requested services, and fulfill your orders.
            </p>
            <div class="centered-container">
                <button class="g-recaptcha submit" >Submit</button>
            </div>       
        </form>
    </div>
</template>

<script>
import Swal from 'sweetalert2'
import { VueReCaptcha } from 'vue-recaptcha-v3'

export default {
    name: 'RequestAQuoteComponent',
    data() {
        return{
            company:'',
            email: '',
            phone: '',
            aircraft: '',
            message: '',
        }
    },
    methods: {
        //Check the token from the captcha
        async onSubmit() {
            Swal.fire({
                title: 'Sending',
                text: 'Please wait',
                color: 'rgb(245,245,245)',
                background: '#303134',
                timer: 5000,
                showConfirmButton: false,
            })
            const action = 'submitform'

            try{
                const token = await this.$recaptchaLoaded().then(() => this.$recaptcha(action));
                // Send the token to your server for verification
                const responseRecaptcha = await this.$store.dispatch('verifyCaptcha', { 'token': token, 'action': action });

                console.log(responseRecaptcha)
                if (responseRecaptcha.success) {
                    // If reCAPTCHA verification succeeds, call sendFormdata()
                    const formDataResponse = await this.sendFormdata();

                    // Continue with the next actions after sendFormdata() resolves
                    this.$router.push({ name: 'request' });
                    Swal.fire({
                        title: "Success!",
                        text: "Thank you for submitting your request! We will now proceed to handle it promptly. Please feel free to contact us if you have any further questions or concerns.",
                        icon: "success",
                        color: 'rgb(245,245,245)',
                        background: '#303134',
                        confirmButtonColor: '#050B13',
                    });
                }else{
                    throw new Error('reCAPTCHA verification failed.')
                }
            } catch (error){
                console.log(error);
                Swal.fire({
                    title: "Oops!",
                    text: "It looks like something went wrong during the sending: " + error.message +" Please try again.",
                    icon: "info",
                    color: 'rgb(245,245,245)',
                    background: '#303134',
                    confirmButtonColor: '#050B13',
                    });
            }
        },

        async sendFormdata() {                
            const formData = {
            company: this.company,
            email: this.email,
            phone: this.phone,
            aircraft: this.aircraft,
            message: this.message
            }
        
            try{
                this.$store.commit('UPDATE_FORM_DATA', formData);
                
                // Promise.race to race between the postForm() function and a timeout promise
                const timeoutPromise = new Promise((resolve, reject) => {
                setTimeout(() => {
                    reject(new Error('Timeout: Still awaiting response from the server.'));
                }, 15000);
                });
                
                // Use Promise.race to wait for the first promise that resolves or rejects
                const resp = await Promise.race([this.$store.dispatch('postForm'), timeoutPromise]);
                
                // If the response is received
                return resp
            } catch (error) {
                throw error;
            }
        }
        
    }     
}
</script>

<style scoped>
    .swal2-popup{
        font-family: Roboto, sans-serif;
    }
</style>

