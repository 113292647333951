<template>
    <!-- A FAIRE -->
    <!-- Ajouter une prop avec si version mobile ou non -->
    
    <!-- FOOTER MOBILE -->
    <footer class="desktop-hide">
        <div id="footer-mobile">
            <p>MSM AVIATION<br> 
                1 impasse du Cluzel,<br>
                69200 Venissieux FRANCE<br>
            </p>
            <div class="popup" onclick="popUpDisclaimer()">Disclaimer
                <span class="popuptext" id="myPopup">
                    <p>The information contained in this website is for general information purposes only. <br>
                        <br> 
                        The information is provided by MSM Aviation and while we endeavour to keep the 
                        information up to date and correct, we make no representations or warranties 
                        of any kind, express or implied, about the completeness, accuracy, reliability, 
                        suitability or availability with respect to the website or the information, 
                        products, services, or related graphics contained on the website for any purpose. <br>
                        Any reliance you place on such information is therefore strictly at your own risk. <br>
                    </p>
                </span>
            </div>   
            <br>
            <span><a href="/assets/GENERAL TERMS AND CONDITIONS OF SALES - MSM Aviation.pdf">General Terms and Conditions of Sale</a></span><br>
            <p>All rights reserved ©2024 MSM Aviation</p>
        </div>
    </footer>

    <!-- FOOTER DESKTOP -->
    <footer class="mobile-hide">
        <div id="footer">
            <p>MSM AVIATION, 1 impasse du Cluzel, 69200 Venissieux FRANCE |  <span><router-link to="/disclaimer">Disclaimer</router-link></span>  | <span><a href="/assets/GENERAL TERMS AND CONDITIONS OF SALES - MSM Aviation.pdf">General Terms and Conditions of Sale</a></span>  | All rights reserved ©2023 MSM Aviation 
            </p>
        </div>
    </footer>

</template>

<script>
export default{
    name: 'FooterComponent'
}
</script>