<template>
    <!-- SECTION CONTACT-->
    <section id="contact">   
    
    <div class="container1">   
        <div class="container2-1">
            <div class="container3">
            <h2>CONTACT</h2>
            <p class="sam">Sam Monnier, CEO</p>

            <div class="container3-1">
                <i class="fa-solid fa-phone"></i>
                <div class="textTel">+33(0)649737713</div> 
            </div>

                <div class="container3-1">
                    <i class="fas fa-envelope"></i>
                    <div class="textMail">contact@msmaviation.com</div>
                </div>

                <div class="container3-2">
                    <img class="flag"
                        alt="France"
                        src="http://purecatamphetamine.github.io/country-flag-icons/3x2/FR.svg"/>
                    <img class="flag"
                        alt="United States"
                        src="http://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg"/>
                    <img class="flag"
                        alt="Spain"
                        src="http://purecatamphetamine.github.io/country-flag-icons/3x2/ES.svg"/>
                    <img class="flag"
                        alt="Portugal"
                        src="http://purecatamphetamine.github.io/country-flag-icons/3x2/PT.svg"/>
                </div>

                <h3>OUR OFFICE</h3>
                <p class="textoffice1">
                    Strategically located in France in Lyon, 
                    our office is at the crossroads of route des Alpes and South of France.
                    Close by major suppliers, we established our office in the former capital of France.
                    Any chance you pass by, feel free to come and say hello to us !</p>

                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d77091.72260835695!2d4.794046746810293!3d45.7635882524913!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47f4c26da48c32c7%3A0x983163d2330b12d8!2s1%20Imp.%20du%20Cluzel%2C%2069200%20V%C3%A9nissieux!5e0!3m2!1sfr!2sfr!4v1710749361592!5m2!1sfr!2sfr" width="600" height="310" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
        <div class="container2-2">
            <RequestAQuoteComponent />
        </div>
    </div>
    </section>
    
</template>
    
<script>
import RequestAQuoteComponent from '@/components/RequestAQuoteComponent.vue';
    export default{
        name: 'Contact',
        components:{
            RequestAQuoteComponent,
        },
    }
    
</script>
