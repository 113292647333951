import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import HomePage from '../views/HomePage.vue'
import WhoWeAre from '../views/WhoWeArePage.vue'
import Capabilities from '../views/CapabilitiesPage.vue'
import Contact from '../views/ContactPage.vue'
import Disclaimer from '../views/DisclaimerPage.vue'
import Request from '../views/RequestPage.vue'
import Stock from '../views/StockPage.vue'
import Dashboard from '../views/dashboard/DashboardHomePage.vue'
import Error404 from '@/views/404.vue'
import { createAuthGuard } from "@auth0/auth0-vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: '/whoWeAre',
    name: 'whoWeAre',
    component: WhoWeAre
  },
  {
    path: '/capabilities',
    name: 'capabilities',
    component: Capabilities
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },
  {
    path: '/disclaimer',
    name: 'disclaimer',
    component: Disclaimer
  },
  {
    path: '/request',
    name: 'request',
    component: Request
  },
  {
    path: '/stock',
    name: 'stock',
    component: Stock
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    beforeEnter: createAuthGuard(app)
  },
  { 
    path: '/:catchAll(.*)',
    name: '404',
    component: Error404
  },

]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { top: 0 }
  }
})

export default router
