<template>
    <!-- SECTION STOCK -->
    <section id="stock">
        <div class="fullcontainer">
            <div class="banner">
                <h2>OUR STOCK</h2>
            </div>
            <div class="container2">
                <div class="container3-1">
                    <h3>Welcome to our helicopter parts stock!</h3>
                    <p class="subtext">Explore our <strong>helicopter parts stock</strong> today and experience the difference 
                        of working with a <strong>trusted and reliable supplier.</strong> 
                        Should you have any <strong>inquiries on pricing</strong> or need assistance, 
                        feel free to contact our team. 
                        We are dedicated to being your go-to destination for all your 
                        <strong>helicopter part needs.</strong> 
                    </p>
                </div>
                <div class="container3-2">
                    <!-- <div class="searchbar">
                        <input type="text" placeholder="Search.." v-model="searchInput">
                        <span class="material-symbols-outlined search-icon">search</span>
                    </div> -->
                    <div class="searchbar relative">
                        <input type="text" placeholder="Search..." v-model="searchInput" @keyup.enter="searchPart(1)" class="search-input">
                        <button @click="searchPart(1)" class="search-button">
                            <span class="material-symbols-outlined">search</span>
                        </button>
                    </div>
                    <button class="contactButton"><a href="mailto:contact@msmaviation.com">CONTACT US FOR PRICING <span class="material-symbols-outlined">mail</span></a></button>
                </div>
                <Spinner v-if="isLoading && !isErrorLoading" />
                <div v-if="isErrorLoading">
                    <p>Looks like Data is not available at the moment. Please try refresh your page...</p>
                </div>
                <div v-if="!isLoading && !isErrorLoading" class="non-scrolling-table">
                    <table cellspacing="0" cellpadding="0">
                        <thead>
                            <tr>
                                <th>Part Number</th>
                                <th>Designation</th>
                                <th>Quantity</th>
                                <th>Aircraft type</th>
                                <th>Condition</th>
                                <th>Certification</th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- Item sold -->
                            <!-- <tr class="sold">
                                <td>350A3119180051</td>
                                <td>STARFLEX EQUIPPED</td>
                                <td>1</td>
                                <td>AS350 355 EC130</td>
                                <td>FN</td>
                                <td>FORM1</td>
                                <td class="sold-line background-invisible"><span class="sold-tag">RECENTLY SOLD</span></td>
                            </tr> -->
                            
                            <tr v-if="isSearch" v-for="item in dataFiltered">
                                <td>{{ item.part_number }}</td>
                                <td>{{ item.designation }}</td>
                                <td>{{ item.quantity }}</td>
                                <td>{{ item.aircraft_type }}</td>
                                <td>{{ item.condition }}</td>
                                <td>{{ item.certification }}</td>                                               
                            </tr>
                            <tr v-else v-for="item in inventoryPaginated">
                                <td>{{ item.part_number }}</td>
                                <td>{{ item.designation }}</td>
                                <td>{{ item.quantity }}</td>
                                <td>{{ item.aircraft_type }}</td>
                                <td>{{ item.condition }}</td>
                                <td>{{ item.certification }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div> 
                <Pagination v-if="!isLoading && !isErrorLoading" 
                    :totalPages=totalPages
                    :currentPage=currentPage
                    @setCurrentPage="setCurrentPage"
                />
            </div>
        </div>
    </section>
</template>


<script setup>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import Spinner from '@/components/state/loading/Spinner.vue'
import Pagination from '@/components/layout/pagination/Pagination.vue'

const store = useStore()
const isLoading = ref(true)
const isErrorLoading = ref(false)
const searchInput= ref('')
const isSearch = ref(false)
const regEx = ref('')

const pageSize = 50;
const currentPage = ref(1);
const inventoryPaginated = computed(() => store.getters.getInventoryPaginated);
const totalPages = computed(() => store.getters.getTotalPages);
const dataFiltered = computed(() => store.getters.getInventoryDataSearch);

onMounted(async ()=> {
    try {
        await getInventoryDataPaginated(currentPage.value, pageSize)
        isLoading.value=false
    } catch (error) {
        isLoading.value=false
        isErrorLoading.value=true
    }
})


const getInventoryDataPaginated = async (page, pageSize) => {
    try{
        await store.dispatch('getInventoryRemoteDataSourcePaginated', {page: page, pageSize: pageSize})
    } catch(error) {
        isErrorLoading.value=true
    } 
}

const setCurrentPage = async (page) => {
    currentPage.value=page
    if(isSearch.value){
        await store.dispatch('getInventoryRemoteDataSourceRegEx', {regex: regEx.value, page: page, pageSize: pageSize})
    }else{
        await store.dispatch('getInventoryRemoteDataSourcePaginated', {page: page, pageSize: pageSize})
    }
}


const searchPart = async (page) => {    
    currentPage.value=page
    if (searchInput.value == '') {
        isSearch.value=false
        await store.dispatch('getInventoryRemoteDataSourcePaginated', {page: page, pageSize: pageSize})
        return
    }
    try {
        isSearch.value=true
        isLoading.value=true
        regEx.value = createRegExHyphenSlash(searchInput.value)
        await store.dispatch('getInventoryRemoteDataSourceRegEx', {regex: regEx.value, page: page, pageSize: pageSize})
        isLoading.value=false
    }catch (error) {
        isErrorLoading.value=true
    }
}

const createRegExHyphenSlash = (search) => {
    if (search !== "") {
        const nakedWord = search.replace(/[-\/]/g, '');
        const fullHyphenatedWord = nakedWord.replace(/[A-Za-z0-9]/g, (match) => match + '-');
        const hyphenatedWord = fullHyphenatedWord.slice(0, -1);
        const searchTermWithHyphenOrSlash = hyphenatedWord.replace(/[-]/g, '[-\/]*');
        const regEx = new RegExp(searchTermWithHyphenOrSlash, 'i')
        return regEx
    }else{
        return ""
    }
    }


</script>