<template>
    <!--MENU DEROULANT-->
    <nav>
    <div class="left-nav mobile-hide">
        <div class="logo-nav"><router-link to="/" /></div>
        <router-link to="/request"><button class="request-nav">Request a quote</button></router-link>
    </div>
        <div id="burger-menu">
            <label for="mobile" ><span class="material-symbols-outlined burger">{{ isMenuOpen ? 'close' : 'menu' }}</span></label>
            <input type="checkbox" v-model="isMenuOpen" id="mobile" role="button" @click="toggleMenu">
            <ul class="menu">
                <li><button @click="scrollToHome()" class="element-menu">HOME</button></li>
                <li><button @click="scrollToWhoWeAre()" class="element-menu">WHO WE ARE</button></li>
                <li><button @click="scrollToCapabilities()" class="element-menu">OUR CAPABILITIES</button></li>
                <li><button @click="scrollToStock()" class="element-menu">STOCK</button></li>
                <li class="desktop-hide"><button @click="scrollToRequest()" class="element-menu">REQUEST A QUOTE</button></li>
                <li><button @click="scrollToContact()" class="element-menu">CONTACT</button></li>
                <!-- <li v-if="isAuthenticated"><button @click="scrollToDashboard()" class="element-menu">DASHBOARD</button></li>
                <div v-if="isAuthenticated" class="picture-user" :style="`background-image: url(${user.picture});`" ></div> -->
                <!-- <div v-if="isAuthenticated">{{ user.nickname }}</div> -->
                <!-- Authentication to be activated -->
                <!-- <li v-if= isAuthenticated><span @click="logout" class="material-symbols-outlined">logout</span></li>
                <li v-else><span @click="login" class="material-symbols-outlined">login</span></li> -->
            </ul>
        </div>
    </nav>
</template>

<script>
import { useAuth0 } from '@auth0/auth0-vue';


export default {
    name: 'NavComponent',
    data() {
        return{
            isMenuOpen: false,
            // user: this.$auth0.user,
            // isAuthenticated: this.$auth0.isAuthenticated,
        }
    },
    methods: {
        //toggle Menu
        toggleMenu(){
            this.isMenuOpen = !this.isMenuOpen;
        },

        //Use Auth0 function 
        login() {
        this.$auth0.loginWithRedirect();
        },

        logout() {
        this.$auth0.logout({ logoutParams: { returnTo: window.location.origin } });
        },
              
        //FUNCTIONS pour rediriger sur les pages en version grand ecran
        //et scroll si version mobile
        scrollToHome(){
            if (window.matchMedia('(max-width: 650px)').matches) {
                document.getElementById("home").scrollIntoView();
                this.isMenuOpen = !this.isMenuOpen;
            } else {
                this.$router.push("/");
            }
        },
        scrollToWhoWeAre(){
            if (window.matchMedia('(max-width: 650px)').matches) {
                document.getElementById("whoWeAre-div").scrollIntoView();
                this.isMenuOpen = !this.isMenuOpen;
            } else {
                this.$router.push("/whoWeAre");  
            }
        },
        scrollToCapabilities() {
            if (window.matchMedia('(max-width: 650px)').matches) {
                document.getElementById("capabilities-div").scrollIntoView();
                this.isMenuOpen = !this.isMenuOpen;
            } else {
                this.$router.push("/capabilities");
            }
        },
        scrollToContact() {
            if (window.matchMedia('(max-width: 650px)').matches) {
                document.getElementById("contact-div").scrollIntoView();
                this.isMenuOpen = !this.isMenuOpen;
            } else {
                this.$router.push("/contact");
            }
        },
        scrollToRequest() {
                document.getElementById("request-div").scrollIntoView();
                this.isMenuOpen = !this.isMenuOpen;
        },
        scrollToStock() {
            if (window.matchMedia('(max-width: 650px)').matches) {
                document.getElementById("stock-div").scrollIntoView();
                this.isMenuOpen = !this.isMenuOpen;
            } else {
                this.$router.push("/stock");
            }  
        },
        scrollToDashboard() {
            this.$router.push("/dashboard");    
        }
    },
}
</script>

<style scopped>

.picture-user {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-image: url(https://avatars.githubusercontent.com/u/92819503?v=4);
    background-size: contain;
}

</style>