import { createStore } from 'vuex'
import axios from 'axios';

// let hostURL
// let apiURL

// if(process.env.NODE_ENV === 'development'){
//   hostURL = 'http://localhost:3001',
//   apiURL = 'http://localhost:3001/api'
// } else {
//   hostURL = 'backend',
//   apiURL = 'backend/api'
// }

const hostURL = process.env.VUE_APP_BACKEND_HOST || 'http://localhost:3001'
const apiURL = process.env.VUE_APP_BACKEND_API_URL || 'http://localhost:3001/api'


export default createStore({
  state: {
    formData: {
      company: '',
      email: '',
      phone: '',
      aircraft: '',
      message:''
    },
    inventory: [],
    inventoryPaginated: [],
    totalPages: 1,
    isAuthenticated: false,
    showComponent: true,
    inventoryDataSearch: [],
  },
  getters: {
    getFormData(state) {
      return state.formData;
    },
    getInventory(state){
      return state.inventory
    },
    getInventoryPaginated(state){
      return state.inventoryPaginated
    },
    getTotalPages(state){
      return state.totalPages
    },
    getInventoryDataSearch(state){
      return state.inventoryDataSearch
    },

  },
  mutations: {
    UPDATE_FORM_DATA(state, formData) {
      state.formData = formData;
    },
    CHANGE_SESSION_STATUS(state) {
      state.isAuthenticated=!state.isAuthenticated;
    },
    COMPONENT_SET_FALSE(state){
      state.showComponent=false;
    },
    UPDATE_INVENTORY(state, inventoryData){
      state.inventory=inventoryData
    },
    UPDATE_INVENTORY_PAGINATED(state, inventoryData){
      state.inventoryPaginated = inventoryData
    },
    UPDATE_TOTAL_PAGES(state, totalPages){
      state.totalPages = totalPages
    },
    UPDATE_INVENTORY_SEARCH(state, inventoryDataSearch){
      state.inventoryDataSearch = inventoryDataSearch
    }
  },
  actions: {
    async postForm({state}){
      const myData = state.formData
      const responsePost = await axios.post(hostURL+'/send-email', myData);
      return responsePost
    },

    async verifyCaptcha(context, myData) {
      const responseRecaptcha = await axios.post(hostURL+'/verify_recaptcha', myData);
      return responseRecaptcha.data
    },

    async changeSessionStatus({commit}) {
      commit("CHANGE_SESSION_STATUS");
    },

    async getInventoryRemoteDataSource({ dispatch }){
      const response = await axios.get(apiURL+'/inventory')
      const cleanData = await dispatch('cleanDataFromBackend', response.data)
      this.commit('UPDATE_INVENTORY', response.data)
    },

    async getInventoryRemoteDataSourcePaginated({state, commit}, { page, pageSize }){
      const response = await axios.get(`${apiURL}/inventory`, {
        params: { page, pageSize }
      });
      commit('UPDATE_INVENTORY_PAGINATED', response.data.items)
      if (state.totalPages !== response.data.totalPages){
        commit('UPDATE_TOTAL_PAGES', response.data.totalPages)
      }
    },

    async getInventoryRemoteDataSourceRegEx({state, commit}, {regex, page, pageSize}){
      try {
        const response = await axios.get(`${apiURL}/inventory/regex`, {
          params: { regex, page, pageSize }
        });
        commit('UPDATE_INVENTORY_SEARCH', response.data.items)
        if (state.totalPages !== response.data.totalPages){
          commit('UPDATE_TOTAL_PAGES', response.data.totalPages)
        }
      } catch (error) {
        console.log(error)
      }
    },


    cleanDataFromBackend(context, array){
      // Fields to remove
      const fieldsToRemove = ['id', 'updatedAt', 'user_name'];
      const cleanData=array
      // Create a new array with cleaned objects
      const cleanedArray = array.map(obj => {
        const cleanedObj = { ...obj }; // Create a copy of the original object

        // Remove specified fields from the object
        fieldsToRemove.forEach(field => {
          delete cleanedObj[field];
        });
        return cleanedObj;
      });

      return cleanedArray;
    }
  },
  modules: {
  }
})
