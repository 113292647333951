<template>

<main>
<!-- SECTION HOME-->
    <a class="desktop-hide expand-absolute" href="#"><span class="material-symbols-outlined">expand_less</span></a>
    <section id="home">
        <div id="image_cover">

            <div class="container-cover">
                <div class="nonMovingDiv-mobile desktop-hide">
                    <div class="logo-mobile desktop-hide"></div>
                </div>
                <div class="nonMovingDiv mobile-hide">
                    <div class="logo mobile-hide"></div>
                </div>
                <div class="container-text">
                    <div class="topText">
                        <h1>Excellence in the skies: <br>
                            delivering helicopter parts at a fair price</h1>
                    </div>
                    <button @click="scrollToWhoWeAre()" class="discoverUs mobile-hide">DISCOVER US</button>
                    <div class="desktop-hide expand expand-bottom"><a href="#whoWeAre-div"><span class="material-symbols-outlined">expand_more</span></a></div>
                </div>
            </div>
        </div>
    </section>

<!-- SECTION WHO WE ARE-->
    <section id="whoWeAre-mobile" class="desktop-hide">

        <div id="whoWeAre-div" class="repere"></div>

        <div class="section-pane">
            <div class="border-bottom reveal margin-extended">
                <h2 class="reveal">WHO WE ARE</h2>
            </div>

            <div class="text-intro reveal">
                <p class="describe-us">MSM Aviation is a <strong>French company</strong> specializing in providing high-quality, 
                    and certified parts for a wide range of helicopter aircrafts.</p>
                <hr>
                <p class="describe-us"> Our primary objective is to be a <strong>reliable</strong> and <strong>transparent worldwide supplier</strong> 
                    of parts for most popular helicopter aircrafts. </p>
                <hr>
                <p class="describe-us"><strong>With 13 years of industry experience</strong>, we have built a strong reputation
                    for our customer service and expertise. </p>
            </div>
        </div>

        <h3>WHY CHOOSE US ?</h3>

        <i class="fa-solid fa-screwdriver-wrench rotate i1"></i>
        <h4>BROAD PRODUCT RANGE AND SERVICES</h4>
        <ul class="service">
            <li>Extensive inventory for most aircrafts</li>
            <li>Supply of floatation and consumable</li>
            <li>Repair services</li>
            <li>Consulting for custom-built solutions</li>
        </ul>

        <i class="fa-solid fa-gears rotate i2"></i>
        <h4>TECHNICAL EXPERTISE</h4>
        <p class="service"> In our team we have <strong>pilots and professionals</strong> who possess in-depth knowledge of helicopter parts 
            and maintenance. </p>
        <p class="service"> Whether you require <strong>technical assistance, expert advice, or consultation,</strong> our knowledgeable
        staff is always ready to assist you with your <strong>specific requirements</strong>.
        </p>

        <i class="fa-solid fa-handshake rotate i3"></i>
        <h4>RELIABLE SERVICE</h4>
        <p class="service">We understand the importance of <strong>minimizing downtime</strong> for helicopter owners and operators.</p> 
        <p class="service"> When you choose us, you can count on <strong>prompt order processing</strong>,
            speedy delivery with associated certifications and after-sales service and support.</p>

        <i class="fa-solid fa-headset rotate i4"></i>
        <h4>CLIENT DIRECT LINE</h4>
        <p class="service"><strong>Because helicopter field is a sensitive and complex ecosystem,</strong> 
            we always have a <strong>direct line</strong> with our customers to keep them constantly up to date 
            with the status of their orders.</p> 
        <p class="service"> We ensure that every customer receives <strong>personalized attention and assistance</strong> 
            from the initial quotation to the delivery of parts or service.</p>


        <i class="fa-solid fa-shield-halved rotate i5"></i>
        <h4>Certified company</h4>
        <p class="service">Our company is certified <strong>ISO 9001</strong> and is quarterly audited.</p>

        <div class="space"></div>

    </section>

<!-- SECTION CAPABILITIES-->
    <section id="capabilities-mobile" class="desktop-hide">

        <div id="capabilities-div" class="repere"></div>

        <div class="section-pane">
            <div class="border-bottom reveal">
                <h2 class="reveal">OUR CAPABILITIES</h2>
            </div>

            <div class="helicopterMaquette-mobile slide-up"></div>
        </div>

        <div class="capabilities">
            <div class="capability serv1 appear">
                <div class="number serv1">01</div>
                <div class="title-capability "> <h3 class="serv1">SUPPLY HELICOPTER PARTS</h3></div>
            </div>
            <div class="text-capability appear">
                <p class="subtext-capability">We can supply <strong class="bold">structural parts</strong> or <strong class="bold">engine components</strong> from the most popular OEM:</p>
                <ul>
                    <h4>STRUCTURAL COMPONENTS</h4>
                    <li>Airbus <br> <div class="aircraft-models blur">EC120 Colibri, AS350/AS355 Ecureuil/Astar, AS365 Dauphin, AS332/EC225 SuperPuma/Cougar/Caracal</div></li>
                    <li>Agusta <br> <div class="aircraft-models blur">AW109, AW169, AW139, AW189</div></li>
                    <li>Sikorsky <br> <div class="aircraft-models blur">S-76</div></li>

                    <h4>ENGINE COMPONENTS</h4>
                    <li>SAFRAN <br> <div class="aircraft-models blur">TurboMeca Parts Level 1 & 2, <br>Series of turboshaft engines: <br> ARRIEL, MAKILA, ARRIUS </div></li>
                    <li>Pratt and Whitney <br><div class="aircraft-models blur">Various series engines: <br>PW150, PW200, PW300, PT6, JT15D, PW100, PW500, PW600 and PW900</div></li>
                    <li>Honeywell <br><div class="aircraft-models blur">Lycoming turboshaft engines / LTS101, any Honeywell part</div></li>
                </ul> 
            </div>
        </div>

        <div class="capabilities appear">
            <div class="capability serv2">
                <div class="number serv2">02</div>
                <div class="title-capability "> <h3 class="serv2">AIRBUS REPAIRS</h3></div>
            </div>
            <div class="text-capability appear">
                <p>From <strong class="bold">general maintenance</strong> and <strong class="bold">component replacements</strong> to <strong class="bold">Makila engine or epicyclics repairs</strong>, 
                    we have the knowledge and skills to handle any 
                    repair task effectively.
                </p>
            </div>
        </div>


        <div class="capabilities appear">
            <div class="capability serv3">
                <div class="number serv3">03</div>
                <div class="title-capability "><h3 class="serv3">SUPPLY FLOTATION DEVICE</h3></div>
            </div>
            <div class="text-capability appear">
                <p class="subtext-capability">Our capabilities cover <strong class="bold">reliable and compliant flotation solutions</strong> for various 
                    helicopter models. Here are some examples: </p>
                <ul class="condensed-list">
                    <li>Life rafts</li>
                    <li>Flotation device</li>
                    <li>Cylinder bottles</li>
                    <li>Life jackets</li>
                    <li>Booster pumps</li>
                    <li>and more</li>
                </ul> 
            </div>
        </div>


        <div class="capabilities appear">
            <div class="capability serv4">
                <div class="number serv4">04</div>
                <div class="title-capability serv4"><h3 class="serv4">SUPPLY OF CONSUMABLES</h3></div>
            </div>
            <div class="text-capability appear">
                <p class="subtext-capability">Our offer also consist of a <strong class="bold">variety of consumables and hardware</strong> required 
                    for helicopter maintenance and repairs, including: </p>
                <ul class="condensed-list">
                    <li>Ground equipement</li>
                    <li>Tools and harware</li>
                    <li>Painting</li>
                    <li>Cabin interior</li>
                    <li>Oil, jet oil, lubricants</li>
                    <li>and more</li>
                </ul>
            </div>
        </div>

        <div class="capabilities appear">
            <div class="capability serv5">
                <div class="number serv5">05</div>
                <div class="title-capability "><h3 class="serv5">AVIONICS SERVICES</h3></div>
            </div>
            <div class="text-capability appear">
                <p class="subtext-capability">We closely work with OEM to meet your <strong class="bold">aircraft's navigation, communication, 
                    and flight management</strong> needs for <strong class="bold">helicopters and airplanes</strong>. 
                    We offer components as well as repair services. </p>
                <ul>
                    <li>Thales <br> <div class="aircraft-models blur"><strong class="bold">Thales Electronical Systems: </strong><br>Starter Generator, Electrical Power Generation System … 
                        <br><p><br></p>
                        <strong class="bold">Thales Avionics:</strong> <br>Automatic Flight Control System, Interactive Avionics Display IAD 68 …</div></li>
                    <li>Honeywell <br> <div class="aircraft-models blur">Autopilots & indicators, Flight Management Systems, Cockpit Displays, Image Processing Module, Control Display Units, Integrated Flight Decks</div></li>
                    <li>Garmin <br> <div class="aircraft-models blur">Flight decks & display, Audio Panels, Navigation & radios, Altimeters, ADS-B & transponders </div></li>

                </ul>
            </div>
        </div>
        <div class="space"></div>
    </section>

<!-- SECTION BANNER IMAGE-->
    <section class=" section-parallax desktop-hide">
        <div class="parallax-img"></div>
    </section>


<!-- SECTION STOCK -->
<section id="stock-mobile" class="desktop-hide">
    <div id="stock-div" class="repere"></div>

    <div class="section-pane">
        <div class="border-bottom reveal">
            <h2 class="reveal">STOCK</h2>
        </div>

        <div class="stock-text">
            <p>Explore our <strong>helicopter parts stock</strong> today and experience the difference 
                of working with a <strong>trusted and reliable supplier.</strong> </p>
        </div>

        <div class="searchbar">
            <input type="text" placeholder="Search..." v-model="searchInput" @keyup.enter="searchPart(1)" class="search-input">
            <button @click="searchPart(1)" class="search-button">
                <span class="material-symbols-outlined">search</span>
            </button>
        </div>

        <div class="scrolling-table">
            <table cellspacing="0" cellpadding="0">
                <thead>
                    <tr>
                        <th>Part Number</th>
                        <th>Designation</th>
                        <th>Qty</th>
                        <th>Aircraft type</th>
                        <th>Cdn</th>
                        <th>Certs</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="isSearch" v-for="item in dataFiltered">
                        <td>{{ item.part_number }}</td>
                        <td>{{ item.designation }}</td>
                        <td>{{ item.quantity }}</td>
                        <td>{{ item.aircraft_type }}</td>
                        <td>{{ item.condition }}</td>
                        <td>{{ item.certification }}</td>                                               
                    </tr>
                    <tr v-else v-for="item in inventoryPaginated">
                        <td>{{ item.part_number }}</td>
                        <td>{{ item.designation }}</td>
                        <td>{{ item.quantity }}</td>
                        <td>{{ item.aircraft_type }}</td>
                        <td>{{ item.condition }}</td>
                        <td>{{ item.certification }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <Pagination 
                    :totalPages=totalPages
                    :currentPage=currentPage
                    @setCurrentPage="setCurrentPage"
                />
        

        <div class="stock-text text-2">
            <p>Should you have any inquiries on pricing or need assistance, feel free to contact our team. 
                We are dedicated to being your go-to destination for all your helicopter part needs.</p>
        </div>



    </div>

</section>




<!-- SECTION REQUEST A QUOTATION-->    
<section id="request-mobile" class="desktop-hide">
<div id="request-div" class="repere"></div>

<div class="section-pane">
    <div class="border-bottom reveal">
        <h2 class="reveal">REQUEST FOR QUOTATION</h2>
    </div>
    <div class="form">
        <form @submit.prevent="onSubmit">
            <p class="subtext">Whether you're involved in aviation maintenance, helicopter repairs, 
                or any other related field, we are here to assist you.
            </p>
            <h4>CONTACT INFORMATION</h4>
                <label for="">Company name *</label>
                <input type="text" id="company" name="company" placeholder="Your company" required v-model="company">
                
                <label for="">Email *</label>
                <input type="email" id="email" name="email" placeholder="example@email.com" required v-model="email">
            
                <label for="">Phone number</label>
                <input type="tel" id="phone" name="phone" placeholder="+3362365842527" v-model="phone">
            <h4>HELICOPTER PARTS DETAILS</h4>
                <label for="">Aircraft type *</label>
                <input type="text" id="aircraft" name="aircraft" placeholder="EC220 - AS365" v-model="aircraft">
                
                <label for="message">Parts & quantity required or message *</label>
                <textarea id="message" name="message" placeholder=
                "350A32-5010-00  ROTOR BRAKE ASSY qty 1 " required v-model="message"></textarea>
            
            <p class="privacy-terms">Your privacy is important to us. We strictly restrict the use of your data to process your inquiries, deliver requested services, and fulfill your orders.
            </p>
            <div class="centered-container">
                <button class="g-recaptcha submit" >Submit</button>
            </div>       
        </form>
    </div>
</div>
</section>

<!-- SECTION CONTACT-->
    <section id="contact-mobile" class="desktop-hide">

        <div id="contact-div" class="repere"></div>

        <div class="border-bottom reveal">
            <h2 class="reveal">CONTACT</h2>
        </div>

        <div class="Sam reveal">
            <h3>SAM MONNIER<br> CEO</h3>
        </div>

        <div class="container3 téléphone appear">
            <i class="fa-solid fa-phone"></i>
            <div class="textTel">+33(0)649737713</div> 
        </div>

        <div class="container3 mail appear">
            <i class="fas fa-envelope"></i>
            <div class="textMail">contact@msmaviation.com</div> 
        </div>

        <div class="office explain off1">
            <p class="titreOffice">OUR OFFICE</p>
            <p class="textoffice1">Strategically located in France in Lyon, 
                our office is at the crossroads of route des Alpes and South of France. <br>
                Close by Airbus suppliers, we established our office in the former capital of France.
                Any chance you pass by, feel free to come and say hello to us ! </p>
        </div>
        <div class="office adresse off2">
            <i class="fa-solid fa-location-dot"></i>   
            <p>MSM Aviation <br>
                1 impasse du Cluzel, <br>
                69200 Vénissieux <br>
                FRANCE <br>
                <br>
                <a href="https://goo.gl/maps/FJuSWD31quB5TCCH8">FIND US ON GOOGLE</a>
            </p>
        </div>

        <div class="desktop-hide expand"><a href="#"><span class="material-symbols-outlined">expand_less</span></a></div>
        <div class="backTop">BACK TO TOP</div>

    </section>




</main>

</template>

<script>
import Swal from 'sweetalert2'
import Pagination from '@/components/layout/pagination/Pagination.vue'

export default {
    name: 'HomePage',
    data() {
        return{
            company:'',
            email: '',
            phone: '',
            aircraft: '',
            message: '',
            searchInput:'',
            pageSize:20,
            currentPage:1,
            isSearch:false,
            isLoading:false
        }
    },
    components: {
        Pagination
    },
    computed: {
        inventoryPaginated(){
            return this.$store.getters.getInventoryPaginated
        },
        totalPages(){
            return this.$store.getters.getTotalPages
        },
        dataFiltered() {
            return this.$store.getters.getInventoryDataSearch
        }
    },
    mounted () {
        (async () => {
            await this.getInventoryDataPaginated(this.currentPage, this.pageSize)
        })();
    },
    methods: {
        scrollToWhoWeAre(){
            if (window.matchMedia('(max-width: 650px)').matches) {
                document.getElementById("whoWeAre-div").scrollIntoView();
            } else {
                this.$router.push("/whoWeAre");  
            }
        },
        //Check the token from the captcha
        async onSubmit() {
            Swal.fire({
                title: 'Sending',
                text: 'Please wait',
                color: 'rgb(245,245,245)',
                background: '#303134',
                timer: 5000,
                showConfirmButton: false,
                regEx:'',
                isError:false,
            })
            const action = 'submitform'

            try{
                const token = await this.$recaptchaLoaded().then(() => this.$recaptcha(action));
                
                // Send the token to your server for verification
                const responseRecaptcha = await this.$store.dispatch('verifyCaptcha', { 'token': token, 'action': action });

                if (responseRecaptcha.success) {
                    // If reCAPTCHA verification succeeds, call sendFormdata()
                    const formDataResponse = await this.sendFormdata();

                    // Continue with the next actions after sendFormdata() resolves
                    // this.$router.push({ name: 'request' });
                    Swal.fire({
                        title: "Success!",
                        text: "Thank you for submitting your request! We will now proceed to handle it promptly. Please feel free to contact us if you have any further questions or concerns.",
                        icon: "success",
                        color: 'rgb(245,245,245)',
                        background: '#303134',
                        confirmButtonColor: '#050B13',
                    });
                }else{
                    throw new Error('reCAPTCHA verification failed.')
                }
            } catch (error){
                console.log(error);
                Swal.fire({
                    title: "Oops!",
                    text: "It looks like something went wrong during the sending: " + error.message +" Please try again.",
                    icon: "info",
                    color: 'rgb(245,245,245)',
                    background: '#303134',
                    confirmButtonColor: '#050B13',
                    closeOnEsc : true,
                    });
            }
        },

        async getInventoryDataPaginated(page, pageSize) {
            try{
                await this.$store.dispatch('getInventoryRemoteDataSourcePaginated', {page: page, pageSize: pageSize})
            } catch(error) {
                this.isError=true
            }
        },

        async setCurrentPage(page) {
            this.currentPage=page
            try {
                if(this.isSearch){
                    await this.$store.dispatch('getInventoryRemoteDataSourceRegEx', {regex: this.regEx, page: page, pageSize: this.pageSize})
                }else{
                    await this.$store.dispatch('getInventoryRemoteDataSourcePaginated', {page: page, pageSize: this.pageSize})
                }
            }catch(error){
                this.isError=true
            }
        },

        async sendFormdata() {                
            const formData = {
            company: this.company,
            email: this.email,
            phone: this.phone,
            aircraft: this.aircraft,
            message: this.message
            }
        
            try{
                this.$store.commit('UPDATE_FORM_DATA', formData);
                
                // Promise.race to race between the postForm() function and a timeout promise
                const timeoutPromise = new Promise((resolve, reject) => {
                setTimeout(() => {
                    reject(new Error('Timeout: Still awaiting response from the server.'));
                }, 15000);
                });
                
                // Use Promise.race to wait for the first promise that resolves or rejects
                const resp = await Promise.race([this.$store.dispatch('postForm'), timeoutPromise]);
                
                // If the response is received
                return resp
            } catch (error) {
                throw error;
            }
        },
        createRegExHyphenSlash(search) {
            if (search !== "") {
                const nakedWord = search.replace(/[-\/]/g, '');
                const fullHyphenatedWord = nakedWord.replace(/[A-Za-z0-9]/g, (match) => match + '-');
                const hyphenatedWord = fullHyphenatedWord.slice(0, -1);
                const searchTermWithHyphenOrSlash = hyphenatedWord.replace(/[-]/g, '[-\/]*');
                const regEx = new RegExp(searchTermWithHyphenOrSlash, 'i');
                return regEx;
            } else {
                return "";
            }
        },

        async searchPart(page) {
            this.currentPage=page
            if (this.searchInput == '') {
                this.isSearch=false
                await this.$store.dispatch('getInventoryRemoteDataSourcePaginated', {page: page, pageSize: this.pageSize})
                return
            }
            try {
                this.isSearch=true
                this.isLoading=true
                this.regEx = this.createRegExHyphenSlash(this.searchInput)
                await this.$store.dispatch('getInventoryRemoteDataSourceRegEx', {regex: this.regEx, page: page, pageSize: this.pageSize})
                this.isLoading=false
            }catch (error) {
                this.isLoading=false
                this.isError=true
            }
        }
        
    },
}
</script>